import React from 'react';

import Dropzone from 'react-dropzone';
import axios from 'axios';


import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { DEFAULT_NUM_COLORS, MAX_FILE_SIZE, DEFAULT_DIMENSION_BLOCK } from '../constants.js';
import {image_uploader, upload_image} from './common_components.css.js';

import UploadImage from '../Images/UploadImage.png';


export class ImageUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.path,
            flag: props.flag
        }
    }

    uploadImage(file, i, filename) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('flag', 'new_image');
        formData.append('num_colors', DEFAULT_NUM_COLORS);
        formData.append('color_change', false);
        formData.append('contour_id', i);
        formData.append('order_id', this.props.order_id);
        formData.append('dimension_block', DEFAULT_DIMENSION_BLOCK);
        var self = this;
        axios.post(this.state.path, formData
        ).then((response) => {
            this.props.uploadHandler(response.data, i);
        }).catch((error) => {
            self.props.generalError(filename);
        });
    }


    render () {
        return(
            <Dropzone onDrop={acceptedFiles => {
                var num_shapes = this.props.num_shapes;
                var temp_accepted_files = [];
                for(let i = 0; i < acceptedFiles.length; i++) {
                    if(acceptedFiles[i].size > MAX_FILE_SIZE) {
                        alert(acceptedFiles[i].name + ' is too large. Please decrease the resolution and resubmit.');
                    } else if(acceptedFiles[i].type === 'image/png' || acceptedFiles[i].type === 'image/jpeg') {
                        temp_accepted_files.push(acceptedFiles[i]);
                    } else {
                        alert(acceptedFiles[i].name + ' is the incorrect filetype, only PNG or JPEG images are allowed.');
                    }
                }
                acceptedFiles = temp_accepted_files;
                if(acceptedFiles.length > 0) {
                    this.props.uploadPreparer(acceptedFiles.length, acceptedFiles);
                    if(this.props.order_id === '') {
                        for(let i = 0; i < acceptedFiles.length; i++) {
                            this.uploadImage(acceptedFiles[i], num_shapes+i, acceptedFiles[i].name);
                        }
                    } else {
                        for(let i = 0; i < acceptedFiles.length; i++) {
                            this.uploadImage(acceptedFiles[i], num_shapes+i, acceptedFiles[i].name);
                        }
                    }
                }
            }} multiple={true}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()} style={image_uploader} >
                                    <input {...getInputProps()} />
                                    <Image src={UploadImage} style={upload_image} />
                                    <p>UPLOAD YOUR IMAGES HERE</p>
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                </section>
                            )}
            </Dropzone>
        );
    }
}

export function ErrorModal(props) {
  return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.modal_header}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                {props.modal_body}
            </p>
        </Modal.Body>
    </Modal>
  );
}

export function TOSModal(props) {
  return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {"Terms of Service"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{height: 300, overflow: 'scroll'}}>
                {terms_of_service}
            </div>
        </Modal.Body>
    </Modal>
  );
}

const terms_of_service = `
    Terms of Use 
    1) Acceptance of Terms; Modification to Terms. 
    Thank you for visiting Hybrid Textiles, a service of Hybrid Textiles LLC. (“Hybrid Textiles”), a Delaware corporation. Hybrid Textiles provides its website and services (collectively the “site”) subject to the following terms of service (“Terms”). 
    By acknowledging these terms, using the site, uploading any data or images or ordering products, you are indicating your agreement to be bound by these terms, including our Privacy Policy, and all revisions thereof. If you do not agree to these Terms, do not accept them and discontinue using and accessing the site. These Terms are applicable to your use of the site regardless of how you accessed the site. 
    We may modify the Terms at any time. If the modifications to the Terms constitute a material change, in Hybrid Textiles good faith reasonable judgment, Hybrid Textiles will notify you via email and/or by posting a notice on the homepage of the site. You should review these Terms from time to time so that you understand the terms and conditions of your use of the site. If you do not agree to any amendment of these Terms, you must immediately stop using the Site. Your continued use of the site after any modification to these Terms constitutes acceptance of the amended Terms. If you ever have any questions about these Terms, please do not hesitate to contact us at support@HybridTextiles.com. 
    If you are accessing the site from the European Union, you must be 16 years and older to use the site. If you are accessing the site from the United States, you must be 13 years and older to use the site. Hybrid Textiles does not specifically collect information about children and the website is not directed to children. If we learn that Hybrid Textiles has collected information from a child under the applicable age, we will delete that information as quickly as possible. If you are under the required age, we will only process your information upon receipt of verified consent from a parent or legal guardian. We recommend that anyone under the applicable age required by law, ask and receive their parents' permission before using Hybrid Textiles or sending information about themselves or anyone else over the Internet. 

    2) Member Account, Password 
    If you register on the site, you will receive an email confirming the creation of a Hybrid Textiles account. Activating your account requires the creation of a password. You are responsible for maintaining the confidentiality of the password and account, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Hybrid Textiles of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Hybrid Textiles cannot be liable for any loss or damage arising from your failure to comply with Section 2. 

    3) Content 
    You acknowledge that Hybrid Textiles is an automated web service that gives you the power to upload, display, offer for sale and/or create customized textile products. Given the automated nature of the service, Hybrid Textiles is not able to review all Content that is uploaded to the site. However, Hybrid Textiles and its designees shall have the right (but not the obligation) in their sole discretion to review and to reject or remove any Content that is available via the site. Upon placing any order through the site, or making designs available for sale, you acknowledge that Hybrid Textiles may review it and the Content it contains for adherence to our guidelines and compliance with the terms and conditions set forth in these Terms. Without limiting the foregoing, Hybrid Textiles and its designees shall have the right to refuse to fulfill any order for any reason and to remove any Content that violates the Terms or is otherwise objectionable to us. Hybrid Textiles shall have the right, at its exclusive discretion to remove Content and/or suspend user accounts that violate these Terms or Hybrid Textiles policies. Hybrid Textiles reserves the right to amend the Terms and its policies described above from time to time at its sole discretion. 
    You acknowledge and agree that you are responsible for the creation and compilation of your Content, and that neither Hybrid Textiles nor any other party involved with the production of any product incorporating such Content, including but not limited to fabric, assumes that responsibility. Hybrid Textiles production of any product depicting your Content shall be pursuant to your order and specification and Hybrid Textiles shall merely function as a manufacturer of such products. Hybrid Textiles’s production of any product depicting your Content, including but not limited to fabric, does not indicate that Hybrid Textiles accepts, approves of or endorses the Content, that the Content complies with applicable laws, or that you are absolved of any liability or harm arising from the use of the Content. 

    By posting Content on Hybrid Textiles and choosing to make it public, other websites or parties are able to copy and re-post that Content, including without limitation, approved third-party websites that have access to the site through its application program interface (API) (discussed in Privacy Policy section below). You agree to hold Hybrid Textiles harmless for any dispute concerning this use. If you choose to display any Hybrid Textiles-hosted image on another website, the image must provide a link back to its listing page on Hybrid Textiles. 
    You hereby agree to waive any claims against Hybrid Textiles and its affiliates, contractors, agents, third party service providers and employees (collectively, “Representatives”) for losses, damages and injuries which are based on or relate to communications or materials made available to the site or posted on the site, including other Content, by other users and persons other than Hybrid Textiles or its Representatives. 

    You represent and warrant that all contact and personal information provided to Hybrid Textiles during the registration process and otherwise shall be accurate and complete. If there are any changes to your contact information, you shall promptly update your registration information with Hybrid Textiles. 
    4) User Conduct 
    When you upload photographs, designs, drawings, text or other content (“Content”) to the site, you keep your copyright and any other intellectual property rights you may have in the Content. You also control the account settings that determine whether or not your Content can be shared or sold through the site. In order to maintain and provide the site, Hybrid Textiles is required to be able to copy, crop, modify, reproduce, display and distribute your Content worldwide. Hybrid Textiles will only perform these actions to the extent necessary to provide the site. By submitting Content to, or utilizing, the site, you grant Hybrid Textiles a royalty-free, non-exclusive, worldwide, transferable license to modify, copy, sell, display, distribute and create derivative works of the Content and the goods created from or based on such Content so long as such process is an essential step of the site. By default, all Content uploaded to the site is not available to the public unless you specifically signify the Content as public. In addition to making your Content available to public, you may also elect to make Content available for sale on the site by agreeing to the terms of the Seller’s Agreement. If you choose to make Content public and/or available for sale on the site, you can also choose to remove them from sale or make the Content private at any time. 
    By making your Content public on the site, you give Hybrid Textiles the right to display or print your Content, including photographing goods made from that Content, for use in materials used to help market both the site and the designers who use the site. Be aware that Google and other search engines will also index public designs and the information included in public user profiles. 
    By uploading Content to the site you represent and warrant that: 
        • You have the lawful right to reproduce and distribute such Content; 
        • The Content does not violate any copyright, trademark, trade secret or other intellectual property right of any third party; 
        • The Content complies with all applicable federal, state, and local laws, regulations and ordinances; 
        • The Content does not invade any individual’s right of privacy or celebrity; 
    In using this site, you also agree: 
        • Not to impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; 
        • Not to upload, download, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, or any other form of solicitation; 
        • Not to upload, download, post, email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; 
        • Not to upload, download, post, email or otherwise transmit false or misleading information; 
        • Not to disrupt or interfere with the security of, or otherwise abuse, the site, or any services, system resources, accounts, servers, or networks connected to or accessible through the site or affiliated or linked websites. 
        • Not to access, tamper with or use non-public areas of the site. Unauthorized individuals attempting to access these areas of the site may be subject to prosecution; 
        • Not to disrupt or interfere with any other user’s enjoyment of the site or affiliated or linked websites, including without limitation, through any trolling, belligerent or antagonistic behavior towards other users or Hybrid Textiles representatives; 
        • Not to collect or store personal information about any other use of the site. 
    Disputes with Others: Should a dispute arise between you and another user or a third party, Hybrid Textiles urges you to contact the user or third party to resolve the dispute amicably. You can report disputes with other users to support@HybridTextiles.com, although Hybrid Textiles cannot guarantee that it will pursue a particular action on your behalf. You may also report, if it is appropriate, a dispute to law enforcement, the postmaster general, or a certified mediation or arbitration entity, as applicable. 
    You hereby release Hybrid Textiles (and Hybrid Textiles officers, directors, agents, subsidiaries, joint ventures and employees) from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes with one or more users, or an outside party. 

    5) Privacy Policy 
    Your privacy is very important to us at Hybrid Textiles. We collect and store personal information when you register, move through the site, make purchases and sell Content. Hybrid Textiles does not share customer information (including email addresses) outside the Hybrid Textiles family of companies unless it is necessary to provide you with products or services available from Hybrid Textiles. This may include service providers that provide support services to Hybrid Textiles or that help Hybrid Textiles market its products and services, administer our programs, operations, fulfill your requests, surveys, contests, sweepstakes and product reviews, or provide you with membership information. We promise that we will never sell or rent your telephone or email information to third party marketers. By using the site and accepting these Terms, you expressly consent to Hybrid Textiles use of your personal information (including using email to communicate with you) as described in the Privacy Policy, which is incorporated herein by reference. 
    You acknowledge and agree that as provided in Hybrid Textiles Privacy Policy that in the event Hybrid Textiles receives an objection asserting that your Content infringes the intellectual property right of another (the “Rights Owner”), that Hybrid Textiles shall have the right, in its discretion, to provide the Rights Owner with your name and contact information as well as information concerning the Content and the use or sale of the Content through the site. You hereby agree to waive any claims against Hybrid Textiles and its Representatives, for the disclosure of information to a Rights Owner 
    Without limiting the foregoing, you acknowledge and agree that Hybrid Textiles may preserve Content for as long as necessary for the purpose for which it was collected and may also disclose Content or information, including your personal information, if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce these Terms; (iii) respond to claims that any Content violates the rights of third-parties; or (iv) protect the rights, property, or personal safety of Hybrid Textiles, its users and the public. We may also disclose personal information as necessary to investigate or prevent violations of these Terms or suspected illegal activity, to trusted partners who work with us under confidentiality agreements, or to a company that acquires or merges with Hybrid Textiles. By using the messaging features on the site you consent that your screen name may be disclosed to the recipients of your messages. You may not use personal information about other Hybrid Textiles users in a way that is inconsistent with Hybrid Textiles Privacy Policy or to harass, intimidate, threaten or demean any other user. By doing so you would violate the terms of this Agreement, and Hybrid Textiles may terminate your account. You can report messages that violate these Terms to support@HybridTextiles.com, although Hybrid Textiles cannot guarantee that it will pursue any particular action on your behalf. You may also report threatening or harassing messages to law enforcement as appropriate. 
    Hybrid Textiles has developed an application program interface (API) that will enable approved third-party websites and software to interact with the site and upload Content, view publicly available Content and/or order products utilizing Content that is made available for sale through the site. You acknowledge and agree that any information or Content that you make publicly available on the site may be accessed and used by these third-party sites. 
    If you choose to make your profile public, your name, screen name and public contact information will be displayed on the site to other users. Hybrid Textiles is not liable for damage incurred due to use of personal data posted in a public profile or public designs on the site. 
    Hybrid Textiles sets and accesses cookies to keep track of login status, and works with third parties like Google to collect anonymous data to help improve and market our service. Please refer to your browser's \"Help\" menu for how to change cookie preferences. Please refer to our Privacy Policy for more details on how we collect and store data. 

    6) Proprietary Rights 
    You acknowledge and agree that, except for your Content, any data, materials or content, including but not limited to designs, text, photos, images, etc. or other material contained or distributed on or through the site by Hybrid Textiles, or its Representatives (“site Content”) is protected by trademarks, service marks, patents, copyrights, or other proprietary rights and laws, and as between you and Hybrid Textiles, shall belong to Hybrid Textiles. You may not use, reproduce or distribute any site Content without the authorization of the owner of such site Content, except for uses permitted by law. 
    You acknowledge and agree that the site and any software used in connection with the site (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. All such Software and the site shall remain the property of Hybrid Textiles and you agree not to modify, rent, lease, loan, sell, distribute, reverse engineer or create derivative works based on the site or the Software, in whole or in part. 

    7) Copyright and Other Intellectual Property Policy 
    Hybrid Textiles respects the intellectual property rights of others. We ask our members to do the same. Hybrid Textiles, in its exclusive discretion, may terminate the accounts of users it believes may be infringing the copyright, trademark or other intellectual property rights of others. 
    It is Hybrid Textiles’s policy to respond expeditiously to claims of infringement. Hybrid Textiles will promptly process and investigate notices of alleged copyright and/or trademark infringement and will take appropriate actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable laws. 
    You acknowledge and agree that as provided in Hybrid Textiles’s Privacy Policy that in the event Hybrid Textiles receives an objection asserting that your Content infringes the copyright and/or trademark right of another (the “Rights Owner”), that Hybrid Textiles shall have the right, in its discretion, to provide the Rights Owner with your name and contact information as well as information concerning the Content and the use or sale of the Content through the site to enable the Rights Owner to contact you directly to address the use of its intellectual property rights. 
    If you are a Rights Owner and believe in good faith that your copyrighted material has been posted on the site in violation of any applicable rights or licenses, and you want this material removed, you must provide the site with a written communication that details the information listed in this section. Please also note that under Section 512(f) of the Copyright Act any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability. You may wish to contact an attorney for legal assistance on this matter prior to submitting a notification. 
    To file a copyright infringement notification with us, you will need to send a written communication that includes substantially the following: 
    a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; 
    b) a description of the copyrighted work(s) claimed to have been infringed; 
    c) an identification of the material that is claimed to be infringing, including a link to or description of where the claimed infringing Content is located on our site so that Hybrid Textiles is capable of finding and verifying its existence; 
    d) contact information, including identification of the copyright owner, your name, address, telephone number, and email address 
    e) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and 
    f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are authorized to act on behalf of the owner of the copyright interest involved. 
    Send the written infringement notice and the materials above to our “Designated Agent” the following address: 
    Copyright Agent 
    Hybrid Textiles, LLC. 
    4030 Wake Forest Rd. ste 349
    Raleigh, NC 27609, USA 
    Email: support@HybridTextiles.com 
    Upon receipt of notices complying, or substantially complying, with the DMCA and this section, Hybrid Textiles will use its reasonable efforts to remove or disable access to any material claimed to be infringing or claimed to be the subject of infringing activity and to remove or disable access to any reference or link to material or activity that is claimed to be infringing. 

    8) Warranty of Ownership and Non-infringement 
    YOU REPRESENT AND WARRANT THAT YOU ARE THE OWNER OF THE CONTENT WHICH YOU SUBMIT AND THAT THE CONTENT DOES NOT INFRINGE UPON THE PROPERTY RIGHTS, INTELLECTUAL PROPERTY RIGHTS (E.G. COPYRIGHTS AND TRADEMARKS) OR OTHER RIGHTS OF OTHERS. YOU ALSO REPRESENT THAT THERE ARE NO OUTSTANDING DISPUTES IN CONNECTION WITH THE PROPERTY RIGHTS, INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS IN THE CONTENT OR ANY PARTS OF THE CONTENT. 

    9) Indemnity 
    You agree to defend, indemnify and hold Hybrid Textiles and Hybrid Textiles’s officers, directors, employees and other Representatives harmless from all judgments, awards, losses, liabilities, costs and expenses, including but not limited to reasonable attorney’s fees, expert witness fees, and costs of litigation arising out of or based on (a) Content you submit, post to or transmit through the site, (b) your use of the site, (c) your connection to the site, (d) your violation of these Terms or (e) your violation of any rights of a third party. 

    10) Termination 
    You agree that Hybrid Textiles, in its sole discretion, may terminate your user name, password, account (or any part thereof) or use of the site, and remove and discard any Content you may have contributed to the site, at any time for any reason or no reason. Hybrid Textiles may also in its sole discretion and at any time modify the site, or any portion thereof, or discontinue providing the site, or any part thereof, with or without notice. Further, you agree that Hybrid Textiles shall not be liable to you or any third-party for any termination of your access to the site. 

    11) Links 
    The site may provide, or third parties may provide, links to other websites or resources. Because Hybrid Textiles has no control of such sites and resources, you acknowledge and agree that Hybrid Textiles is not responsible for the availability of such sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further acknowledge and agree that Hybrid Textiles shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource. 

    12) Pricing 
    Prices for products on Hybrid Textiles are described on our website and are incorporated into these Terms by reference. All prices are for the currency reflected on the Site at the time of purchase. Prices and products may change at Hybrid Textiles’s discretion. 

    13) Shipping 
    Title and risk of loss for all products ordered by you shall pass to you on Hybrid Textiles’s shipment to the shipping carrier. You shall be responsible for payment of all shipping, insurance, customs charges and other transportation costs for shipment of products ordered by you through the site, unless otherwise stated on the site or agreed by Hybrid Textiles (such as through a special offer). 

    14) Deleting Content or Accounts 
    If you wish to terminate your Hybrid Textiles account, you may do so within your account settings. 
    If you choose to delete Content from the site, it will no longer be available or visible on the site. We may retain copies of Content in our archives to the extent required by our systems for backing up and protecting our database and website or for other administrative purposes. If you purchase Content and then delete it from your account, we preserve a copy of the Content in order to fulfill the order. Likewise if you make Content available for sale to others and it is purchased before you delete the Content, we keep the Content in order to be able to fulfill the order. We do not use backup copies of deleted Content except as provided herein. 
    If you delete your account, Hybrid Textiles reserves the right to keep any information in its archives that it reasonably determines is necessary to comply with its legal obligations. Hybrid Textiles does not retain residual rights to deleted or retired work other than those outlined above. 

    15) DISCLAIMER OF WARRANTIES 
    YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THIS SITE AND THE INFORMATION, SERVICES, PRODUCTS, AND MATERIALS AVAILABLE THROUGH IT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. 
    TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Hybrid Textiles AND ITS REPRESENTATIVES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF TITLE, OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Hybrid Textiles OR THROUGH OR FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. 
    Hybrid Textiles DOES NOT MAKE ANY WARRANTY THAT (A) THE SITE, ITS CONTENT OR ANY PRODUCTS ORDERED THROUGH THE SITE WILL MEET YOUR REQUIREMENTS, OR (B) THAT THE SITE OR SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE, OR (C) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS, OR (D) THAT DEFECTS, IF ANY, WILL BE CORRECTED. 

    16) LIMITATION OF LIABILITY 
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES INCLUDING, WITHOUT LIMITATION, ITS OWN NEGLIGENCE, SHALL Hybrid Textiles OR ITS REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SITE OR ANY PRODUCTS ORDERED THROUGH THE SITE. IN THE EVENT THAT YOU OBJECT TO ANY OF THESE TERMS OR ARE DISSATISFIED WITH YOUR USE OF THE SITE, YOUR ONLY RECOURSE IS TO IMMEDIATELY DISCONTINUE YOUR USE OF THE SITE AND IN THE EVENT THAT ANY PRODUCTS OBTAINED THROUGH THE SITE ARE DEFECTIVE, YOUR REMEDY AND Hybrid Textiles’S SOLE OBLIGATION SHALL BE RETURN OF THE DEFECTIVE PRODUCTS AND, UPON DETERMINATION BY Hybrid Textiles OF SUCH DEFECTS, REFUND OF ANY AMOUNTS PAID BY YOU TO Hybrid Textiles FOR SUCH DEFECTIVE PRODUCTS. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. 
    You agree that any claim or cause of action arising out of or related to your use of the site or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. 

    17) GENERAL 
    If any provision of this Agreement is invalid, illegal or unenforceable under any applicable statute or rule of law, such provision shall be deemed amended to achieve as nearly as possible the same economic effect as the original provision and the remaining provisions of this Agreement shall in no way be affected or impaired. 
    Hybrid Textiles shall have no liability for any failure or delay resulting from any condition beyond the reasonable control of Hybrid Textiles, including but not limited to governmental action or acts of terrorism, earthquake, fire, flood or other acts of God, labor conditions, power failures and Internet disturbances. 
    This Agreement will be governed by the laws of the State of North Carolina. Hybrid Textiles may elect to resolve any controversy or claim arising out of or relating to these Terms or the site by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association and you hereby agree to consent to such arbitration. Any such controversy or claim shall be arbitrated on an individual basis and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in Raleigh, North Carolina, and judgment on the arbitration award may be entered in any court having jurisdiction thereof. Either party may seek any interim or preliminary relief from a court of competent jurisdiction in Raleigh, NC, necessary to protect the rights or the property of you or Hybrid Textiles (or its Representatives), pending the completion of arbitration. 

`;
